import React from 'react';
import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { get } from '../../core/utils';
import Home from './Home';
import Layout from '../../components/Layout';
import { localeUrl } from '../../core/url';
import routes from './home.routes';
import { setGameCategory } from '../../actions/gamebrowser';

defineMessages({
  homeTitle: {
    id: 'page.home.title',
    defaultMessage: 'The title description for the home page',
    message: 'The title description for the home page',
    description: 'The title description for the home page',
  },
  homeDescription: {
    id: 'page.home.description',
    defaultMessage: 'The SEO description for the home page',
    message: 'The SEO description for the home page',
    description: 'The SEO description for the home page',
  },
  slugFeatured: {
    id: 'slug.featured',
    defaultMessage: 'featured',
    description: 'The SEO description for the home page',
  },
  slugVideoSlots: {
    id: 'slug.videoslots',
    defaultMessage: 'slots',
    description: 'The SEO description for the home page',
  },
  slugChristmas: {
    id: 'slug.christmas',
    defaultMessage: 'christmas',
    description: 'The SEO description for the home page',
  },
  slugClassicSlots: {
    id: 'slug.classicslots',
    defaultMessage: 'classic-slots',
    description: 'The SEO description for the home page',
  },
  slugJackpotGames: {
    id: 'slug.jackpotGames',
    defaultMessage: 'jackpot-games',
    description: 'The SEO description for the home page',
  },
  slugTableGames: {
    id: 'slug.tableGames',
    defaultMessage: 'table-games',
    description: 'The SEO description for the home page',
  },
  slugAll: {
    id: 'slug.all',
    defaultMessage: 'all',
    description: 'The SEO description for the home page',
  },
  slugLiveLobby: {
    id: 'slug.liveLobby',
    defaultMessage: 'live-lobby',
    description: 'The SEO description for the home page',
  },
});

export default function action(context, params) {
  const { intl } = context.store.getState();
  const categories = {
    FEATURED: intl.messages[context.locale]['slug.featured'],
    VIDEOSLOTS: intl.messages[context.locale]['slug.videoslots'],
    CLASSICSLOTS: intl.messages[context.locale]['slug.classicslots'],
    JACKPOTGAMES: intl.messages[context.locale]['slug.jackpotGames'],
    TABLEGAMES: intl.messages[context.locale]['slug.tableGames'],
    ALL: intl.messages[context.locale]['slug.all'],
    LIVELOBBY: intl.messages[context.locale]['slug.liveLobby'],
    CHRISTMAS: 'christmas',
  };

  // context.store.dispatch(setGameCategory('casino', get(params, 'category')));

  if (!params.path) {
    if (get(params, 'category')) {
      context.store.dispatch(
        setGameCategory('casino', get(params, 'category')),
      );
    }
    // if (process.env.BROWSER && window.AsgFWLoaded) {
    //   if (window.AsgFWGamesLoaded) {
    //     context.store.dispatch(setCategory(_.defaultTo(key, 'FEATURED')));
    //     //context.store.dispatch(fetchData());
    //   }else{
    //     window.AsgFW.setOnGamesListUpdated((data) => {
    //       context.store.dispatch(setCategory(_.defaultTo(key, 'FEATURED')));
    //       //context.store.dispatch(fetchData());
    //     });
    //     }
    //   } else if (process.env.BROWSER && !window.AsgFWLoaded) {
    //     document.addEventListener('fw_asgReady', () => {
    //     window.AsgFW.setOnGamesListUpdated((data) => {
    //       context.store.dispatch(setCategory(_.defaultTo(key, 'FEATURED')));
    //       //context.store.dispatch(fetchData());
    //     });
    //   });
    // }else{
    if ('noTransition' in context && !context.noTransition) {
      // context.store.dispatch(resetFilter());
      // context.store.dispatch(setCategory(_.defaultTo(key, 'FEATURED')));
    }
    // context.store.dispatch(fetchData());
    // }
    const key = _.findKey(categories, (val) => val === get(params, 'category'));

    return {
      name: context.name,
      index: context.index,
      params: { ...params, lobbyType: 'general' },
      component: (
        <Layout>
          <Home />
        </Layout>
      ),
      chunks: ['home'],
      title: intl.messages[context.locale]['page.home.title'],
      description: intl.messages[context.locale]['page.home.description'],
      canonicalUrl: !get(params, 'category')
        ? localeUrl('home', context.locale, {
            ...params,
            lobby: 'lobby',
            lobbyType: 'general',
            category: get(params, 'category') ?? '',
          })
        : localeUrl('lobby', context.locale, {
            ...params,
            lobby: 'lobby',
            lobbyType: 'general',
            category: get(params, 'category') ?? '',
          }),
      ssr: false,
      ...{
        ssr: process.env.BROWSER // || context.cookies.h2pushes
          ? context.store.getState().router.activeRoute.ssr
          : context.cookies.isLoggedIn // ||  _.defaultTo(key, 'FEATURED') !== 'FEATURED'
          ? false
          : {
              framework: true,
              customJs: [],
            },
      },
      // ssr: false,
      hreflang: routes,
    };
  }
  return null;
}
